<template>
  <div class="statistics">
    <Crumbs></Crumbs>
    <el-row class="statistics-top">
      <div class="statistics-top-left"></div>
      <div class="statistics-top-right">
        <el-button class="statistics-top-btn" size="small" type="primary" @click="downloadExcel"
          >导出</el-button
        >
      </div>
    </el-row>
    <el-row class="statistics-cen">
      <!-- 表格 -->
      <table class="statistics-table">
        <tbody>
          <tr>
            <th class="table-top" style="width: 8%;">
              <span class="right"><i style="display: block;float: right;">部门</i></span>
              <span class="slash1"></span>
              <span class="left" style=""
                ><i style="display: block;float: left;margin-top: 15px;">项目</i></span
              >
            </th>
            <th
              class="table-top"
              :style="{ width: 92 / tableData[0].length + '%' }"
              style="padding: 0px;"
              v-for="item in tableData[0]"
            >
              {{ item }}
            </th>
          </tr>
          <tr>
            <th>离职人数</th>
            <th v-for="item in tableData[1]">{{ item }}</th>
          </tr>
          <tr>
            <th>新进人数</th>
            <th v-for="item in tableData[2]">{{ item }}</th>
          </tr>
          <tr>
            <th>原有人数</th>
            <th v-for="item in tableData[3]">{{ item }}</th>
          </tr>
          <tr>
            <th>现有总量</th>
            <th v-for="item in tableData[4]">{{ item }}</th>
          </tr>
        </tbody>
      </table>
    </el-row>
    <el-row class="statistics-btm">
      <div class="btm-top">
        <!-- 柱形图 -->
        <div id="btm-top-left"></div>
        <!-- 饼图 -->
        <div id="btm-top-right"></div>
      </div>
      <div class="btm-bootm">
        <!-- 折线图 -->
        <div id="btm-bootm-btm"></div>
        <template v-if="jobAll > 0">
          <div class="btm-bootm-all">
            <span
              >总:<i>{{ jobAll }}</i></span
            >
          </div>
        </template>
      </div>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
  },
  data() {
    return {
      tableData: [[], [], [], [], []],
      jobAll: 0,
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    // 导出
    async downloadExcel() {
      this.$api.user.exportStatistic(this.tableData3).then((res) => {
        const fileName = `人员统计报告.xlsx`;
        const blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      });
    },

    // 获取数据
    async getData() {
      const res = await this.$api.user.getStatisticReport();
      for (let item of res.data) {
        this.tableData[0].push(item.department);
        this.tableData[1].push(item.departNum);
        this.tableData[2].push(item.newNum);
        this.tableData[3].push(item.originalNum);
        this.tableData[4].push(item.total);
      }
      this.lzEcharts(this.tableData[0], this.tableData[1]);
      this.rzEcharts(this.tableData[0], this.tableData[2]);
      this.zzEcharts(this.tableData[0], this.tableData[4]);
    },

    // 柱形图
    lzEcharts(a, b) {
      let departName = a.deepClone();
      departName.pop();
      let departNum = b.deepClone();
      departNum.pop();
      let show = [];
      departNum.forEach((v, i) => {
        show.push({
          name: departName[i],
          value: v,
        });
      });
      var chartDom = document.getElementById("btm-top-left");
      var myChart = echarts.init(chartDom, "dark");
      var option;
      option = {
        grid: {},
        backgroundColor: "#3c3c3c",
        title: {
          top: "10px",
          text: "离职人数",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c} 人",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          orient: "vertical",
          x: "right", //可设定图例在左、右、居中
          y: "center", //可设定图例在上、下、居中
          data: departName,
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
            data: departName,
          },
        ],
        yAxis: [{ type: "value" }],
        series: [
          {
            name: "",
            type: "bar",
            data: show,
            itemStyle: {
              normal: {
                color: function(params) {
                  //首先定义一个数组
                  var colorList = [
                    "#C33531",
                    "#EFE42A",
                    "#64BD3D",
                    "#EE9201",
                    "#29AAE3",
                    "#B74AE5",
                    "#0AAF9F",
                    "#E89689",
                    "#8A2BE2",
                    "#FF1493",
                    "#7FFFAA",
                    "#0000FF",
                    "#29D289",
                    "#592D89",
                  ];
                  return colorList[params.dataIndex];
                },
                // 以下为是否显示
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#4d4e50",
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },

    // 饼图
    rzEcharts(a, b) {
      let departName = a.deepClone();
      departName.pop();
      let newNum = b.deepClone();
      newNum.pop();
      let show = [];
      newNum.forEach((v, i) => {
        show.push({
          name: departName[i],
          value: v,
        });
      });

      var chartDom = document.getElementById("btm-top-right");
      var myChart = echarts.init(chartDom, "dark");
      var option;
      option = {
        backgroundColor: "#3c3c3c",
        title: {
          top: "10px",
          text: "各部门新进人数统计",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} 人",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 150,
          top: 70,
          bottom: 20,
          data: departName,
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: show,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              normal: {
                show: true,
                position: "inner", // 数值显示在内部
                formatter: "{c}", // 格式化数值百分比输出
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },

    // 折线图
    zzEcharts(a, b) {
      let departName = a.deepClone();
      departName.pop();
      let total = b.deepClone();
      total.pop();
      let show = [];
      total.forEach((v, i) => {
        show.push({
          name: departName[i],
          value: v,
        });
      });
      var chartDom = document.getElementById("btm-bootm-btm");
      var myChart = echarts.init(chartDom, "dark");
      var option;
      this.jobAll = b[b.length - 1];
      option = {
        grid: {},
        backgroundColor: "#3c3c3c",
        title: {
          top: "10px",
          text: "各部门现有人员统计",
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}人",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: departName,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: show,
            type: "line",
            color: "white",
            itemStyle: { normal: { label: { show: true } } },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.statistics {
  padding: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .statistics-top {
    margin-bottom: 5px;

    .statistics-top-left {
      margin: 5px;
      height: 40px;

      .day {
        font-size: 20px;
        margin-right: 20px;
      }
    }

    .statistics-top-right {
      position: relative;

      .statistics-top-btn {
        position: absolute;
        right: 8px;
        top: -35px;
        width: 90px;
      }
    }
  }

  .statistics-cen {
    padding: 0 10px;

    .statistics-table {
      width: 100%;
      text-align: center;

      th,
      tr,
      td {
        border: 1px solid #838383;
        color: #080808;
        background-color: #f2f2f2e3;
      }

      td,
      th {
        height: 50px;
        padding: 20px;
      }

      .table-top {
        background-color: #404040;
        color: #fff;
      }
    }

    /* 斜线 */
    .slash1 {
      position: absolute;
      display: block;
      top: 0;
      left: 11px;
      /* 斜边边长 */
      /* Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) */
      /* Math.sqrt(Math.pow(150, 2) + Math.pow(80, 2)) = 170 */
      width: 150px;
      height: 1px;
      background-color: #838383;
      /* 旋转角度计算公式 */
      /*  Math.atan(height / width) * 180 / Math.PI  */
      /*  Math.atan(80 / 150) * 180 / Math.PI  = 28.072486935852954 */
      transform: rotate(32deg);
      transform-origin: top left;
    }

    .left {
      padding-bottom: 100px;
    }
  }

  .statistics-btm {
    .btm-top {
      display: flex;
      margin-top: 10px;

      #btm-top-left {
        padding-right: 10px;
        height: 400px;
        width: 850px;
        // background-color: #404040;
      }

      #btm-top-right {
        flex: 1;
        height: 400px;
      }
    }

    .btm-bootm {
      position: relative;

      #btm-bootm-btm {
        width: 100%;
        height: 400px;
        padding-top: 10px;
      }

      .btm-bootm-all {
        position: absolute;
        right: 8%;
        top: 30px;
        background-color: #cccc00;
        width: 130px;
        height: 35px;
        border-radius: 8px;
        text-align: center;
        line-height: 35px;
        color: #000;

        // transform: translate(-50%, -50%);
        i {
          font-weight: 700;
          font-style: oblique;
          font-size: 24px;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
